// Packages
import React, {useEffect, useState} from 'react'
import clsx from "clsx";
import {useDispatch, useSelector} from 'react-redux'
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import {Sticky, StickyContainer} from "react-sticky";
import ScrollAgent from "react-scroll-agent";
import {useParams} from "react-router";
import _has from "lodash/has";
import {Link as RouterLink, useLocation} from "react-router-dom";

import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import ButtonBase from "@material-ui/core/ButtonBase";
import Link from "@material-ui/core/Link";
import Fade from '@material-ui/core/Fade';
import {Hidden} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

// Actions, constants and helpers
import {PAGES_SLUGS} from "../../constants";
import {ShareUrl} from "../../helpers/shareURL";
import {
    emptyWishlist,
    getWishlistFromLocalStorage,
    getWishlistFromUuid,
    removeAccessoryFromWishlist,
    removeCarFromWishlist
} from "./actions";
import * as actions from "../AccessoryDetailsPage/actions"
import {fetchPage} from "../../actions/pages"
import {fetchProduct} from "../../actions/products";

// Custom components
import SectionBox from "../../components/SectionBox";
import Seo from "../../components/seo/Seo";
import {appStyle} from "../../theme/appStyle";
import toLocale from "../../helpers/toLocale";
import SpaceBox from "../../components/SpaceBox";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import LazyImage from "../../components/LazyImage";
import itemDetailsStyles from "../../theme/itemDetailsStyles";
import TabsWidgetPrimary from "../../components/TabsWidgetPrimary";
import ProgressFullScreen from "../../components/ProgressFullScreen";

function WishlistPage() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {urlUuid} = useParams();
    const [invalidWl, makeInvalid] = useState(false);
    const [wlTitle, changeTitle] = useState('');

    const wishListedAccessories = useSelector(state => state.wishlist.pageData.accessories);
    const wishListedCars = useSelector(state => state.wishlist.pageData.cars);
    const wishlistUuid = useSelector(state => state.wishlist.pageData.uuid);
    const wishlistRequest = useSelector(state => state.wishlist.wishlistRequest);
    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.WISHLIST, {}));

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(pageData)) {
            dispatch(fetchPage(PAGES_SLUGS.WISHLIST));
        }
    }, [])

    useEffect(() => {
        if (urlUuid) {
            dispatch(getWishlistFromUuid(urlUuid))
        } else {
            dispatch(getWishlistFromLocalStorage())
        }
    }, [])

    useEffect(() => {
        if(!(_isEmpty(wishListedAccessories) && _isEmpty(wishListedCars))) {
            if (!invalidWl) {
                changeTitle(`accesorii care sporesc rafinamentul și versatilitatea vehiculului dvs.`);
            } else {
                changeTitle('Wishlist invalid!');
            }
        } else {
            changeTitle(`Niciun produs in wishlist`);
        }
    }, [wishListedAccessories, wishListedCars, invalidWl])

    const makeWlInvalid = () => {
        makeInvalid(true);
    }

    return (
        <React.Fragment>
            {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={pageData.data.meta_data}/>}
            {wishlistRequest
                ? <ProgressFullScreen loading={wishlistRequest}/>
                : <SectionBox>
                    <Container>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant={"h1"} align={"center"}
                                            className={`${classes.weight300} ${classes.pageTitle}`}>
                                    Wish list
                                </Typography>
                                {!invalidWl &&
                                    <Box display={"flex"} mt={6}></Box>
                                }
                                <Typography variant={"subtitle2"} align={"center"}
                                            className={`${classes.weight300} ${classes.pageSubtitle}`}>
                                    {wlTitle}
                                </Typography>
                            </Grid>

                            {!invalidWl &&
                                <Grid item xs={12}>
                                    <StickyContainer>
                                        <ScrollAgent
                                            nav={({current, positions}) => {
                                                {if (!_isEmpty(wishListedAccessories) && !_isEmpty(wishListedCars)) {
                                                    return (
                                                        <Sticky>
                                                            {props => {
                                                                return <div
                                                                    className={`${props.isSticky && classes.fullWidthSticky}`}
                                                                    style={{
                                                                        ...props.style,
                                                                        width: `100%`,
                                                                        left: 0,
                                                                        right: 0,
                                                                        top: 64,
                                                                        marginBottom: `2.5rem`
                                                                    }}
                                                                >
                                                                    <TabsWidgetPrimary
                                                                        current={current}
                                                                        positions={positions}
                                                                        labels={["Accesorii", "Mașini"]}
                                                                    />
                                                                </div>
                                                            }}
                                                        </Sticky>
                                                    )
                                                }}
                                            }}
                                            detectEnd={false}
                                            selector="[data-scrollspy]"
                                            threshold="center"
                                        >

                                    {/*Accessories*/}
                                    {!_isEmpty(wishListedAccessories) &&
                                    <>
                                        <Grid container className={classes.wishlistParent}
                                              data-scrollspy
                                        >
                                            {wishListedAccessories.map((slug, index) => <WishlistAccessoryCard key={index} slug={slug} urlUuid={urlUuid} makeWlInvalid={makeWlInvalid}/>)}
                                        </Grid>

                                        <Grid container item xs={12} justify={"center"} className={classes.stickyContainer}>
                                            <Button
                                                component={RouterLink}
                                                variant="contained"
                                                color="primary"
                                                classes={{root: classes.button}}
                                                to={`/${PAGES_SLUGS.ACCESSORIES_OFFERS}/${wishlistUuid}`}
                                            >
                                                Cere ofertă pentru toate accesoriile
                                            </Button>
                                        </Grid>
                                    </>
                                    }

                                    {/*Spacing between the two lists when both are visible*/}
                                    {(!_isEmpty(wishListedAccessories) && !_isEmpty(wishListedCars)) &&
                                    <>
                                        <SpaceBox/>
                                        <Divider/>
                                        <SpaceBox/>
                                    </>
                                    }

                                    {/*Cars*/}
                                    {!_isEmpty(wishListedCars) &&
                                    <>
                                        <Grid container className={classes.wishlistParent}
                                              data-scrollspy
                                        >
                                            {wishListedCars.map((slug, index) => <WishlistCarCard key={index} slug={slug} urlUuid={urlUuid} makeWlInvalid={makeWlInvalid}/>)}
                                        </Grid>
                                        <Divider/>
                                    </>
                                    }
                                    <SpaceBox/>
                                    <RenderButtons
                                        pageData={pageData}
                                        urlUuid={urlUuid}
                                        wishlistUuid={wishlistUuid}
                                        isEmpty={_isEmpty(wishListedAccessories) && _isEmpty(wishListedCars)}
                                    />
                                    <SpaceBox/>

                                        </ScrollAgent>
                                    </StickyContainer>
                                </Grid>
                            }
                        </Grid>
                    </Container>
                </SectionBox>
            }
        </React.Fragment>
    )
}

function WishlistAccessoryCard(props) {
    const {slug, urlUuid, makeWlInvalid} = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();

    const accessory = useSelector(state => _get(state.accessoryDetails.data, slug, {}));
    const accessoryError = useSelector(state => _get(state.accessoryDetails.error, slug, {}));
    const loading = useSelector(state => state.accessoryDetails.request);
    const updatingWishlist = useSelector(state => state.wishlist.request);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(accessory)) {
            dispatch(actions.fetchAccessory(slug))
        }
    }, [])

    useEffect(() => {
        const errorResponse = _get(accessoryError, 'errorResponse', null);
        if(errorResponse === 404) {
            makeWlInvalid();
        }
    }, [accessoryError])

    return (
        <Fade in={!loading}>
            <Grid container spacing={3} className={classes.cardMargin}>
                <Grid item xs={12} md={5} lg={6}>
                    <RouterLink to={{pathname: `${PAGES_SLUGS.ACCESSORY_DETAIL}/${slug}`, state: {from: location.pathname}}}>
                        <LazyImage src={getSuppotedFileType(_get(accessory, "photos[0].thumb", []))} ratio={56}
                               isAnimated={true} alt={_get(accessory, 'base_name', '')}/>
                    </RouterLink>
                </Grid>
                <Grid item xs={12} md={7} lg={6}>
                    <Box display={"flex"} justifyContent={"space-between"} flexDirection="column" height="100%">
                        <Typography className={classes.sku} component="p">
                            # {_get(accessory, 'external_cab_variant_id', '')}
                        </Typography>

                        <RouterLink to={{pathname: `${PAGES_SLUGS.ACCESSORY_DETAIL}/${slug}`, state: {from: location.pathname}}} className={classes.title}>
                            <Typography variant="h2" >
                                {_get(accessory, 'base_name', '')}
                            </Typography>
                        </RouterLink>

                        <Typography component="p" className={classes.category}>
                            {_get(accessory, 'categorie', '')} / {_get(accessory, 'subcategorie', '')}
                        </Typography>

                        <Typography component={"p"} className={classes.shortDescription}>{accessory.short_description}</Typography>

                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                            <Typography
                                component={"div"}
                                className={classes.rootPrice}>
                                Preț final: <span className={classes.price}>
                                        {toLocale(_get(accessory, 'final_price', ''))}€ + TVA
                                    </span>
                                {accessory.final_price !== accessory.base_price && <div className={classes.oldPrice}>
                                    Redus de la: <span
                                    className={classes.oldPriceLineThrough}>{toLocale(_get(accessory, 'base_price', ''))}€</span> +
                                    TVA (-{`${toLocale(_get(accessory, "discount_percentage", ""))}`}%)
                                </div>}
                            </Typography>

                            <Button
                                component={RouterLink}
                                variant="contained"
                                color="primary"
                                classes={{root: classes.button}}
                                to={`/${PAGES_SLUGS.ACCESSORY_OFFER}/${_get(accessory, 'slug', '')}`}
                            >
                                Cere ofertă
                            </Button>
                        </Box>
                        <Box>
                            <Divider className={classes.topMarginDivider}/>
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                                {!urlUuid &&
                                <ButtonBase
                                    focusRipple
                                    component={Link}
                                    className={classes.underlineLinkButton}
                                    onClick={() => dispatch(removeAccessoryFromWishlist(slug))}
                                    disabled={updatingWishlist}
                                >
                                    <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                        <Icon className={clsx("brand-icon-remove", classes.icon)}/>
                                        <Hidden smDown>Șterge din listă</Hidden>
                                        {updatingWishlist &&
                                        <CircularProgress size={24} className={classes.buttonProgress}/>}
                                    </Box>
                                </ButtonBase>
                                }
                                <ButtonBase
                                    focusRipple
                                    component={RouterLink}
                                    className={classes.underlineLinkButton}
                                    to={`/accessories?hierarchicalMenu[hierarchicalCategories.lvl0]=${encodeURIComponent(accessory.categorie)}`}
                                >
                                    <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                        <Icon className={clsx("brand-icon-offers", classes.icon)}/>
                                        <Hidden smDown>Alte produse similare</Hidden>
                                    </Box>
                                </ButtonBase>
                                <ButtonBase
                                    focusRipple
                                    component={Link}
                                    className={classes.underlineLinkButton}
                                >
                                    <ShareUrl
                                        title={_get(accessory, 'base_name', '')}
                                        text={_get(accessory, 'short_description', '')}
                                        url={`${window.location.origin}/${PAGES_SLUGS.ACCESSORY_DETAIL}/${_get(accessory, 'slug', '')}`}>
                                        <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                            <Icon className={clsx("brand-icon-share", classes.icon)}/>
                                            <Hidden smDown>Share</Hidden>
                                        </Box>
                                    </ShareUrl>
                                </ButtonBase>
                            </Box>
                            <Divider/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Fade>
    )
}

function WishlistCarCard(props) {
    const {slug, urlUuid, makeWlInvalid} = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();

    const car = useSelector(state => _get(state.products.data, slug, {}));
    const carError = useSelector(state => _get(state.products.error, slug, {}));
    const loading = useSelector(state => state.products.request);
    const updatingWishlist = useSelector(state => state.wishlist.request);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(car)) {
            dispatch(fetchProduct(slug))
        }
    }, [])

    useEffect(() => {
        const errorResponse = _get(carError, 'errorResponse', null);
        if(errorResponse === 404) {
            makeWlInvalid();
        }
    }, [carError])

    const showDiscountPrice = Boolean(car.client_invoice_price) && car.client_invoice_price !== car.total_car_price;

    return (
        <Fade in={!loading}>
            <Grid container spacing={3} className={classes.cardMargin}>
                <Grid item xs={12} md={5} lg={6}>
                    <RouterLink to={{pathname: `/${PAGES_SLUGS.DETAILS}/${slug}`, state: {from: location.pathname}}}>
                        <LazyImage src={getSuppotedFileType(_get(car, "photos[0].thumb", []))} ratio={56}
                                   isAnimated={true} alt={_get(car, 'name', '')}/>
                    </RouterLink>
                </Grid>
                <Grid item xs={12} md={7} lg={6}>
                    <Box display={"flex"} justifyContent={"space-between"} flexDirection="column" height="100%">
                        <Typography className={classes.sku} component="p">
                            # {_get(car, 'rom_ref', '')}
                        </Typography>

                        <RouterLink to={{pathname: `/${PAGES_SLUGS.DETAILS}/${slug}`, state: {from: location.pathname}}} className={classes.title}>
                            <Typography variant="h2">
                                {_get(car, 'name', '')}
                            </Typography>
                        </RouterLink>

                        <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
                            {_has(car, "fuel") && <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                                <Icon className={clsx("brand-icon-fuel", classes.optionsListIcon)}/>
                                <Typography variant="caption">{_get(car, "fuel", '')}</Typography>
                            </Box>}
                            {_has(car, "transmission_type") &&
                            <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                                <Icon className={clsx("brand-icon-transmission", classes.optionsListIcon)}/>
                                <Typography variant="caption">{_get(car, "transmission_type", '')}</Typography>
                            </Box>}
                            {_has(car, "transmission") && <Box display={"flex"} alignItems={"center"} className={classes.optionItem}>
                                <Icon className={clsx("brand-icon-traction", classes.optionsListIcon)}/>
                                <Typography variant="caption">{_get(car, "transmission", '')}</Typography>
                            </Box>}
                        </Box>

                        {!_get(car, "archived", false) &&
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                            <Typography
                                component={"div"}
                                className={classes.rootPrice}>
                                Preț final: <span className={classes.price}>
                                        {toLocale(_get(car, 'client_invoice_price', ''))}€ + TVA
                                    </span>
                                {showDiscountPrice &&
                                <div className={classes.oldPrice}>
                                    Redus de la: <span
                                    className={classes.oldPriceLineThrough}>{toLocale(_get(car, 'total_car_price', ''))}€</span> +
                                    TVA (-{`${toLocale(_get(car, "discount_percentage", 0))}`}%)
                                </div>
                                }
                            </Typography>

                            <Button
                                component={RouterLink}
                                variant="contained"
                                color="primary"
                                classes={{root: classes.button}}
                                to={`/${PAGES_SLUGS.OFFERS}/${_get(car, 'slug', '')}`}
                            >
                                Cere ofertă
                            </Button>
                        </Box>}
                        <Box>
                            <Divider className={classes.topMarginDivider}/>
                            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                                {!urlUuid &&
                                <ButtonBase
                                    focusRipple
                                    component={Link}
                                    className={classes.underlineLinkButton}
                                    onClick={() => dispatch(removeCarFromWishlist(slug))}
                                    disabled={updatingWishlist}
                                >
                                    <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                        <Icon className={clsx("brand-icon-remove", classes.icon)}/>
                                        <Hidden smDown>Șterge din listă</Hidden>
                                        {updatingWishlist &&
                                        <CircularProgress size={24} className={classes.buttonProgress}/>}
                                    </Box>
                                </ButtonBase>
                                }
                                <ButtonBase
                                    focusRipple
                                    component={RouterLink}
                                    className={classes.underlineLinkButton}
                                    to={`/${PAGES_SLUGS.PRODUCTS}/?model=${car.model}`}
                                >
                                    <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                        <Icon className={clsx("brand-icon-offers", classes.icon)}/>
                                        <Hidden smDown>Alte produse similare</Hidden>
                                    </Box>
                                </ButtonBase>
                                <ButtonBase
                                    focusRipple
                                    component={Link}
                                    className={classes.underlineLinkButton}
                                >
                                    <ShareUrl
                                        title={_get(car, 'name', '')}
                                        text={'Descopera cele mai bune oferte ale momomentului pentru modelul Volvo preferat'}
                                        url={`${window.location.origin}/${PAGES_SLUGS.DETAILS}/${_get(car, 'slug', '')}`}>
                                        <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                            <Icon className={clsx("brand-icon-share", classes.icon)}/>
                                            <Hidden smDown>Share</Hidden>
                                        </Box>
                                    </ShareUrl>
                                </ButtonBase>
                            </Box>
                            <Divider/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Fade>
    )
}

function RenderButtons(props) {
    const {pageData, urlUuid, wishlistUuid, isEmpty} = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const updatingWishlist = useSelector(state => state.wishlist.request);

    if (isEmpty)
        return (
            <Grid container justify={"center"}>
                <Grid item xs={6}>
                    <Grid container>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            classes={{root: `${classes.buttonSpacer} ${classes.flex}`}}
                            component={RouterLink}
                            to={`/${PAGES_SLUGS.PRODUCTS}`}
                        >
                            Caută mașini
                        </Button>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            classes={{root: `${classes.buttonSpacer} ${classes.flex}`}}
                            component={RouterLink}
                            to={`/${PAGES_SLUGS.ACCESSORIES_INTRO}`}
                        >
                            Caută accesorii
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )

    return (
        <Grid container item xs={12} justify={"center"}>
            {!urlUuid &&
            <Button
                variant="outlined"
                size="large"
                color="primary"
                classes={{root: classes.buttonSpacer}}
                startIcon={<Icon className="brand-icon-remove"/>}
                onClick={() => dispatch(emptyWishlist())}
                disabled={updatingWishlist}
            >
                Șterge wish list
            </Button>
            }

            <ShareUrl
                title={_get(pageData, 'data.meta_data.meta_title', 'Home | Oferte Volvo')}
                text={_get(pageData, 'data.meta_data.meta_description', 'Descopera cele mai bune oferte ale momomentului pentru modelul Volvo preferat')}
                url={`${window.location.origin}/${PAGES_SLUGS.WISHLIST}/${wishlistUuid}`}>
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        classes={{root: classes.buttonSpacer}}
                        startIcon={<Icon className="brand-icon-heart"/>}
                    >
                        Share wish list
                    </Button>
            </ShareUrl>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...itemDetailsStyles(theme),
    fullWidthSticky: {
        backgroundColor: `#fafafa`,
        zIndex: 1101
    },
    selected: {},
    cardMargin: {
        marginBottom: theme.spacing(4)
    },
    wishlistParent: {
        marginBottom: theme.spacing(2)
    },
    sku: {
        color: '#707070',
        fontWeight: '300',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
        paddingBottom: theme.spacing(1),
    },
    title: {
        marginBottom: theme.spacing(2),
        textDecoration: `none`
    },
    category: {
        color: '#707070',
        fontWeight: '300',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
        paddingBottom: theme.spacing(1),
    },
    shortDescription: {
        fontWeight: '300',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: `0.37px`,
        paddingBottom: theme.spacing(2),
    },
    button: {
        backgroundColor: "#284E80",
    },
    stickyContainer: {
        position: `sticky`,
        bottom: 0,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.default,
    },
    rootPrice: {
        ...itemDetailsStyles(theme).rootPrice,
        paddingBottom: 0
    },
    topMarginDivider: {
        marginTop: theme.spacing(3)
    },
    icon: {
        ...appStyle(theme).icon,
        [theme.breakpoints.down('sm')]: {
            margin: `0 !important`
        },
    },
    underlineLinkButton: {
        ...appStyle(theme).underlineLinkButton,
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
    },
    buttonSpacer: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    },
    // Car card
    optionItem: {
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    optionsListIcon: {
        fontSize: "24px",
        marginRight: theme.spacing(2),
    }
}))

export default WishlistPage;
