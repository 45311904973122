import React, {Component} from 'react';
import {connectRange} from 'react-instantsearch-dom';
import {Box, withStyles} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import filterStyles from "../../../theme/filterStyles";
import {resetLabel} from "../../../constants/algolia";

const inputDebounceTimer = 600;

const customStyles = theme => ({
    ...filterStyles(theme),
    inputContainer: {
        height: 32,
        marginBottom: theme.spacing(2)
    },
    dash: {
        width: 32,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textAlign: `center`
    },
    // Fast styles :))
    priceInput: {
        width: 1,
        flex: 1,
        fontFamily: 'Volvo Novum,Arial,sans-serif',
        fontSize: 14,
        lineHeight: `20px`,
        padding: 6,
        border: `1px solid #ccc`,
    }
});


class CustomRangeInput extends Component {
    state = {
        min: '',
        max: '',
        selectedId: null,
        customInputFlag: false,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.currentRefinement.min !== this.props.currentRefinement.min
            || prevProps.currentRefinement.max !== this.props.currentRefinement.max
        ) {
            /**
             * Disable component update if the refinement came from a custom input
             */
            // console.log(`updating with ${this.props.currentRefinement.min}-${this.props.currentRefinement.max}`);
            if (!this.state.customInputFlag) {
                this.setFiltersValues();
            } else {
                this.setState({
                    customInputFlag: false
                });
            }
        }
    }

    setFiltersValues = () => {
        const priceRange = this.props.currentRefinement;
        const {staticPriceRanges} = this.props;
        if (!priceRange.min && !priceRange.max) return null;

        /**
         * TODO: Algolia sends min and max values from the API (default?) which overwrite the currentRefinement object's values
         * We'll overwrite those with undefined when initializing and when filtering
         */
        const algoliaStatsMin = this.props.min;
        const algoliaStatsMax = this.props.max;
        priceRange.min = (priceRange.min === algoliaStatsMin)
            ? undefined
            : priceRange.min;
        priceRange.max = (priceRange.max === algoliaStatsMax)
            ? undefined
            : priceRange.max;
        /**
         * There will be "issues" when an interval contains values outside the min-max range
         * The URL will be set to 0-max instead of min-max
         */

        if (priceRange.min === undefined && priceRange.max === undefined) {
            /**
             * If the input was cleared from navigation (or anything else?)
             * No need to check if state is already emptied; it runs the same number of times
             * and it doesn't re-trigger renders
             */
            this.setState({
                min: '',
                max: '',
                selectedId: null
            });

            return null;
        }

        if (priceRange.min === undefined && priceRange.max !== undefined) {
            /// If there's only a max value
            const matched = staticPriceRanges.find((item) => {
                return item.end === parseInt(priceRange.max);
            })
            // console.log('matched lower than', matched);
            if (matched) {
                this.setState({
                    selectedId: matched.id,
                });
            } else {
                this.setState({
                    max: priceRange.max,
                });
            }

        } else if (priceRange.min !== undefined && priceRange.max === undefined) {
            /// If there's only a min value or an exceeding range was selected (50k-200k)
            const matched = staticPriceRanges.find((item) => {
                return item.start === parseInt(priceRange.min);
            })
            // console.log('matched between range or exceeding max value', matched);
            if (matched) {
                this.setState({
                    selectedId: matched.id,
                });
            } else {
                this.setState({
                    min: priceRange.min,
                });
            }
        } else if (priceRange.min !== undefined && priceRange.max !== undefined) {
            /// If both values are present
            const matched = staticPriceRanges.find((item) => {
                return (item.start === parseInt(priceRange.min) && item.end === parseInt(priceRange.max));
            })
            // console.log('both values', matched);

            if (matched) {
                this.setState({
                    selectedId: matched.id,
                });
            } else {
                this.setState({
                    min: priceRange.min,
                    max: priceRange.max,
                });
            }
        }
    }

    predefinedRefinement = (values, id) => {
        const refineMin = values.min <= this.props.min ? undefined : values.min;
        const refineMax = values.max >= this.props.max ? undefined : values.max;
        /**
         * Can't use refine function is values exceed the min-max interval
         * But there is no issue when converting the URL to search state
         */

        if (this.state.min === '' && this.state.max === '' && this.state.selectedId === id) {
            this.clearRefinement();
            return null;
        }

        this.setState({
            min: '',
            max: '',
            selectedId: id,
        }, this.props.refine({min: refineMin, max: refineMax}))
    }

    customRefinement = (values) => {
        /**
         * Flow
         * Input change -> trigger refine -> component updates -> we need to disable setFiltersValues
         * otherwise, the inputs will be cleared if value.min < algolia min (and max)
         */
        if (this.state.min === values.min && this.state.max === values.max && this.state.selectedId === null) return null;
        const refineMin = values.min <= this.props.min ? this.props.min : values.min;
        const refineMax = values.max >= this.props.max ? this.props.max : values.max;
        // console.log('input values ', values);

        clearTimeout(this.debouncedSetState);

        this.setState({
                min: values.min,
                max: values.max,
                selectedId: null
            }, () => {
                this.debouncedSetState = setTimeout(() => {
                    const numericMin = parseFloat(values.min) || 0;
                    const numericMax = parseFloat(values.max) || 0;
                    if (numericMin < 0
                        || numericMax < 0
                    ) {
                        return null;
                    }
                    this.setState({
                        customInputFlag: true
                    },() => {
                        // console.log('triggering refine with ', refineMin, refineMax);
                        this.props.refine({min: refineMin, max: refineMax})
                    });
                }, inputDebounceTimer);
            }
        );
    }

    clearRefinement = () => {
        this.setState({
            min: '',
            max: '',
            selectedId: null
        }, this.props.refine({min: '', max: ''}))
    }


    render() {
        const {currentRefinement, min, max, classes, expanded, staticPriceRanges} = this.props;
        // console.log('currentRefinement in numericMenu', currentRefinement);
        // console.log(this.props);
        // console.log(this.state);
        return (
            <div className={classes.root}>
                <div className={classes.filterBox}>
                    <Accordion defaultExpanded={expanded} classes={{root: classes.expansionRoot}}>
                        <AccordionSummary
                            classes={{
                                root: classes.expansionButton,
                                content: classes.expansionButtonContent,
                                expandIcon: classes.icon
                            }}
                            expandIcon={<span className={`brand-icon-down-arrow`}/>}>
                            <Typography variant={"body1"}
                                        className={`${classes.categoryTitle} ${(currentRefinement.min || currentRefinement.max) && classes.selectedFilters}`}>Preț</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    {staticPriceRanges.map(item => {
                                        if (
                                            item.end < this.props.min
                                            || item.start > this.props.max
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <FormControlLabel
                                                key={item.id}
                                                className={classes.formLabel}
                                                classes={{label: classes.customLabel}}
                                                control={
                                                    <Checkbox
                                                        checkedIcon={
                                                            <div className={classes.customCheckbox}>
                                                            </div>}
                                                        icon={
                                                            <div className={classes.emptyCustomCheckbox}>
                                                            </div>}
                                                        color={'primary'}
                                                        className={classes.smallerPadding}
                                                        checked={item.id === this.state.selectedId}
                                                        onClick={event => {
                                                            event.preventDefault();
                                                            this.predefinedRefinement({
                                                                min: item.start,
                                                                max: item.end
                                                            }, item.id)
                                                        }}
                                                        value={item.label}/>
                                                }
                                                label={item.label}
                                            />
                                        )
                                    })}

                                    <Box
                                        className={classes.inputContainer}
                                        display="flex"
                                        flexDirection="row">
                                        <input
                                            className={classes.priceInput}
                                            type="number"
                                            // min and max values not used yet
                                            // Have mechanics which returns null when input prices are negative or min>max
                                            // min={min}
                                            // max={max}
                                            step={100}
                                            value={this.state.min || ''}
                                            onChange={event =>
                                                this.customRefinement({
                                                    min: event.currentTarget.value,
                                                    max: this.state.max
                                                })
                                            }
                                        />
                                        <Box alignSelf={"center"} className={classes.dash}>-</Box>
                                        <input
                                            className={classes.priceInput}
                                            type="number"
                                            min={min}
                                            max={max}
                                            step={100}
                                            value={this.state.max || ''}
                                            onChange={event =>
                                                this.customRefinement({
                                                    min: this.state.min,
                                                    max: event.currentTarget.value
                                                })
                                            }
                                        />
                                    </Box>

                                    <Box alignSelf="flex-start">
                                        <Typography
                                            variant={"body2"}
                                            onClick={this.clearRefinement}
                                            disabled={!(currentRefinement.min || currentRefinement.max)}
                                            className={
                                                `${classes.resetButton} ${classes.capitalize} 
                                            ${!(currentRefinement.min || currentRefinement.max)
                                                    ? classes.disabled
                                                    : classes.pointer}`
                                            }
                                        >
                                            {resetLabel}
                                        </Typography>
                                    </Box>

                                </FormGroup>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        );
    }
}

CustomRangeInput = withStyles(customStyles)(CustomRangeInput);

CustomRangeInput = connectRange(CustomRangeInput);
export default CustomRangeInput;
