import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Configure, connectSearchBox, InstantSearch, Stats,} from 'react-instantsearch-dom';
import qs from 'qs'
import {withRouter} from 'react-router'
import _map from 'lodash/map'
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
/*constants*/
import {autocompleteHits, ProductListHitsPerPage} from "./constants";
import {
    nonEmptySearchClient,
    accessoriesIndexName,
    searchClient,
    sortAccessories
} from "../../constants/algolia";
/*actions*/
import * as actions from "./actions";
/*ui*/
import {withStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
/*components*/
import AlgoliaSortByMobile from "../../components/ContentBlocks/Cars/AlgoliaSortByMobile";
import AlgoliaSortByDesktop from "../../components/ContentBlocks/Cars/AlgoliaSortByDesktop";
import AlgoliaPagination from "../../components/ContentBlocks/Cars/AlgoliaPagination";
import AlgoliaAutoComplete from "../../components/ContentBlocks/Cars/AlgoliaAutoComplete";
import AlgoliaAccFiltersMobile from "../../components/accessories/AlgoliaAccFiltersMobile";
import AlgoliaAccHits from "../../components/accessories/AlgoliaAccHits";
import filterStyles from "../../theme/filterStyles";
import listingStyles from "../../theme/listingGeneralStyles";
/*helpers*/
import extractFilterName from "../../helpers/extractFilterName";
import RenderAccessoryFilters from "../../components/Algolia/RenderAccessoryFilters";
import {appStyle} from "../../theme/appStyle";

const VirtualSearchBox = connectSearchBox(() => null);

/**
 * ms until the URL updates. This is required for performance so there won't be too many pushes on filtering
 * @type {number}
 */
const ulrDebounceTimer = 200;

const customStyles = theme => ({
    ...appStyle(theme),
    ...filterStyles(theme),
    ...listingStyles(theme)
});

class AccessoryListing extends Component {
    state = {
        openFilterDialog: false,
        openFilterSideMenu: false,
        searchState: {
            query: this.props.defaultQuery
        },
        facets: {},
        hierarchicalMenu: {},
        openSearchDialog: false,
    };

    componentDidMount() {
        this.fetchDynamicFacets();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * Update the state when clicking the bottom navigation button
         * Or when using See all in autocomplete
         */
        if (this.props.location.search !== prevProps.location.search) {
            this.setState({searchState: this.urlToSearchState(this.props.location)})
        }

        /**
         * Save the facets we get from Algolia in this.state with key: value
         * extractFilterName: Algolia-facet-name; ex.: category: category.name
         * This is required when encoding and decoding the URLs
         */
        // console.log('this.props.facets', this.props.facets);
        if (_isEmpty(this.state.facets) && this.props.facets.length !== 0) {
            const facetNameSync = {};
            const hierarchicalSync = {};
            this.props.facets.map((facet) => {
                if (facet.includes('hierarchical')) {
                    hierarchicalSync[facet] = facet;
                } else {
                facetNameSync[extractFilterName(facet)] = facet
                }
            })
            // console.log('facetNameSync', facetNameSync);
            // console.log('hierarchicalSync', hierarchicalSync);
            this.setState({
                facets: {
                    ...facetNameSync
                },
                hierarchicalMenu: {
                    ...hierarchicalSync
                }
            }, () => {
                /**
                 * Because we have dynamic facets, we need those to convert the URL to search state
                 * This is why the searchState is empty and not initialized on mount
                 */
                this.setState({searchState: this.urlToSearchState(this.props.history.location)})
            })
        }
    }

    fetchDynamicFacets = () => {
        this.props.fetchFacets();
    };

    handleFiltersDialog = () => {
        this.setState({openFilterDialog: !this.state.openFilterDialog});
    };

    onSearchStateChange = searchState => {
        // console.log(this.searchStateToUrl(this.props.location.pathname, searchState));
        // console.log(searchState);

        clearTimeout(this.debouncedSetState);

        this.debouncedSetState = setTimeout(() => {
            this.props.history.push(this.searchStateToUrl(this.props.history.location.pathname, searchState),
                searchState)
        }, ulrDebounceTimer);

        this.setState({searchState});
    };

    createURL = state => {
        // console.log('createURL state:', state);
        const noFacetsSelected = () => {
            if (_isEmpty(state.refinementList)) return true
            return Object.values(state.refinementList).every(item => item === '');
        }

        const noRangeSelected = () => {
            if (!state.range) return true
            return _get(state.range, 'final_price.min', '') === '' && _get(state.range, 'final_price.max', '') === '';
        }

        const isDefaultRoute =
            // No query string
            !state.query
            // First page
            && state.page === 1
            // All facets are empty arrays or none exists
            && noFacetsSelected()
            // No sorting
            && (!state.sortBy || state.sortBy === accessoriesIndexName)
            // Price input values
            && noRangeSelected()
            // No hierarchical menu
            && !state.hierarchicalMenu

        // Not used; fully replaced this mechanics with custom input values
        // No numeric filtering
        // && !state.multiRange

        if (isDefaultRoute) {
            return '';
        }

        const queryParameters = {};

        if (state.query) {
            queryParameters.query = state.query;
        }
        if (state.page !== 1) {
            queryParameters.page = state.page;
        }
        if (state.sortBy) {
            const sorting = state.sortBy.replace(`${accessoriesIndexName}_`, "");
            /**
             * accessoriesIndexName is the unsorted index
             * accessoriesIndexName + '_sort_type'
             * Equal if there is no sort
             */
            if (sorting !== accessoriesIndexName) {
                queryParameters.sortBy = sorting;
            }
        }

        /**
         * Process the refinements if any
         */
        if (state.refinementList) {
            // console.log('state.refinementList', state.refinementList);
            for (let [key, value] of Object.entries(state.refinementList)) {
                // console.log(`${key}: ${value}`);
                if (value) {
                    queryParameters[extractFilterName(key)] = value;
                }
            }
        }

        /**
         * Process the inputs
         */
        if (!noRangeSelected()) {
            const priceRange = state.range.final_price;
            // console.log(priceRange);
            if (!priceRange.min && priceRange.max) {
                /// If there's only a max value
                queryParameters.price = `0:${priceRange.max}`
            } else if (priceRange.min && !priceRange.max) {
                /// If there's only a min value
                queryParameters.price = `${priceRange.min}`
            } else {
                /// If both values are present
                queryParameters.price = `${priceRange.min}:${priceRange.max}`
            }
        }

        /**
         * Process the hierarchical menu
         */
        if (state.hierarchicalMenu) {
            /**
             * Only one branch of this menu can be selected, but leaving a loop
             * We populate it only of the value of the object is not an empty string
             * hierarchicalMenu: {hierarchicalCategories.lvl0: ""}
             */
            for (let [key, value] of Object.entries(state.hierarchicalMenu)) {
                if (value) {
                    queryParameters.hierarchicalMenu = state.hierarchicalMenu;
                }
            }
        }

        const queryString = qs.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: 'repeat',
        });

        return `${queryString}`;
    };

    searchStateToUrl = (pathname, searchState) => {
        // console.log('searchStateToUrl', searchState);
        return searchState ? `${pathname}${this.createURL(searchState)}` : '';
    }

    urlToSearchState = location => {
        const queryParameters = qs.parse(location.search.slice(1));
        // console.log('queryParameters', queryParameters);
        const {query = '', page = 1, price = ''} = queryParameters;
        const sortBy = queryParameters.sortBy ? `${accessoriesIndexName}_${queryParameters.sortBy}` : accessoriesIndexName
        const refinementList = {};
        let hierarchicalList = {};
        const range = {};
        // console.log('urlToSearchState this.state:', this.state);

        /**
         * Parse the facets and see if there are any in refinementList
         */
        // console.log('refinementList', refinementList);
        _map(Object.keys(queryParameters), (parameter) => {
            // console.log('parameter:', parameter);
            if (this.state.facets[parameter]) {
                // console.log(parameter, 'in Alogilia is', this.state.facets[parameter]);
                /**
                 * All values in refinementList must be arrays even if it contains a single element
                 */
                refinementList[this.state.facets[parameter]] = Array.isArray(queryParameters[parameter]) ? queryParameters[parameter] : [queryParameters[parameter]].filter(Boolean)
            }
            // console.log('queryParameters[parameter]', queryParameters[parameter]);
            if (parameter === "hierarchicalMenu") {
                hierarchicalList = queryParameters[parameter];
            }
        })
        // console.log('refinementList:', refinementList);
        // console.log('[hierarchicalList:', hierarchicalList);

        /**
         * Change this if price URL notation changes
         * Current:
         * valMin:valMax
         * valMin:
         * Need to check if the priceRange from the url contains one element
         */
        if (price !== '') {
            const priceRange = price.split(':');
            /**
             * Mechanics is similar to multiRange
             * Moved the refining logic to InputMenu
             */

            if (priceRange.length === 1) {
                // console.log(`priceRange.length ${priceRange.length}`);
                /**
                 * If the greaterThan numericMenu was selected
                 * of if the InputMenu has only min value
                 * ["75000"]
                 */
                range.final_price = {min: priceRange[0]}
            } else if (priceRange[0] === '0') {
                // console.log(`priceRange[0] ${priceRange[0]}`);
                /**
                 * If the lowerThan numericMenu was selected
                 * of if the InputMenu has only max value
                 * ["0", "40000"]
                 */
                range.final_price = {max: priceRange[1]}
            } else {
                // console.log(`${priceRange[0]}-${priceRange[1]}`);
                /**
                 * If interval was selected in the numericMenu
                 * of if the InputMenu has both min and max values
                 * ["28000", "42000"]
                 */
                range.final_price = {min: priceRange[0], max: priceRange[1]}
            }
        }

        return {
            query: query,
            page,
            sortBy: sortBy,
            refinementList: {
                ...refinementList
            },
            hierarchicalMenu: {
                ...hierarchicalList
            },
            range: {
                ...range
            }
        };
    };

    render() {
        const {classes} = this.props
        return (
            <Grid container alignContent={"stretch"} className={classes.listingContainer}>
                {(_isEmpty(this.state.facets)) ? (
                    <Box
                        width={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}>
                        <CircularProgress size={24}/>
                    </Box>
                ) : (
                    <InstantSearch indexName={accessoriesIndexName}
                                   searchClient={searchClient}
                                   onSearchStateChange={this.onSearchStateChange}
                                   searchState={this.state.searchState}
                                   createURL={this.createURL}
                    >

                        <Grid item xs={12}>
                            <Typography variant={"h1"} align={"center"}
                                        className={`${classes.weight300} ${classes.pageTitle}`}>
                                Accesorii Volvo
                            </Typography>
                            <Typography variant={"subtitle2"} align={"center"}
                                        className={`${classes.weight300} ${classes.pageSubtitle}`}>
                                accesorii care sporesc rafinamentul și versatilitatea vehiculului dvs.
                            </Typography>
                        </Grid>

                        <Grid item xs={12} className={classes.searchBarContainer}>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={3} xs={false}>
                                </Grid>
                                <Grid item xl={8} lg={10} md={9} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid container item xs={12} lg={3} alignItems="center"
                                              className={classes.searchSubtitle}>
                                            <Typography variant={"subtitle2"}
                                                        className={`${classes.weight300}`}>
                                                <Stats
                                                    translations={{
                                                        stats(nbHits) {
                                                            return nbHits === 1
                                                                ? `${nbHits} accesoriu listat`
                                                                : `${nbHits} accesorii listate`;
                                                        },
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item md={6} xs={12} className={classes.searchInputParent}>
                                            <div className={`${classes.relative}`}>
                                                {/*Contains both desktop and mobile version*/}
                                                {/*Separate instance so each has it's own refinement*/}
                                                <InstantSearch indexName={accessoriesIndexName} searchClient={nonEmptySearchClient}>
                                                    <AlgoliaAutoComplete indexName={accessoriesIndexName}/>
                                                    <Configure hitsPerPage={autocompleteHits}/>
                                                </InstantSearch>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}
                                              className={`${classes.mobileFilterParent} ${classes.hiddenMdUp}`}>
                                            <Button
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                aria-describedby="sortToggle"
                                                onClick={this.handleFiltersDialog}
                                                className={`${classes.sortButton}`}
                                            >

                                                <Typography variant={"body1"}
                                                            className={`${classes.weight300} ${classes.sortButtonText}`}><Icon
                                                    className={`${classes.iconRightMargin} brand-icon-filter`}/>Filtrează</Typography>
                                                <Icon className={`${classes.sortIcon} brand-icon-down-arrow`}
                                                      color={"primary"}/>
                                            </Button>
                                        </Grid>
                                        <AlgoliaAccFiltersMobile
                                            facets={this.props.facets}
                                            open={this.state.openFilterDialog}
                                            onClose={this.handleFiltersDialog}
                                            onSearchStateChange={this.onSearchStateChange}
                                            searchState={this.state.searchState}
                                        />
                                        <Grid container item xs={6} lg={3} alignItems="center" justify="flex-end"
                                              className={`${classes.sortParent} ${classes.hiddenXlUp}`}>
                                            <AlgoliaSortByMobile
                                                defaultRefinement={accessoriesIndexName}
                                                items={sortAccessories}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xl={2} lg={2} md={3}
                              className={`${classes.filterContainer} ${classes.hiddenSmDown}`}
                        >
                            <VirtualSearchBox defaultRefinement={this.state.searchState.query}/>
                            {/*This creates a clickable link which adds the `menu` object to the search state*/}
                            {/*<Menu attribute="categories.name" />*/}

                            <Typography variant={"body1"}
                                        className={`${classes.weight300} ${classes.mechanicsTitle}`}>
                                Filtrează după specificații:
                            </Typography>
                            <RenderAccessoryFilters facets={this.props.facets}/>
                            <Configure hitsPerPage={ProductListHitsPerPage}/>
                        </Grid>

                        <Grid item xl={8} lg={10} md={9} sm={12} xs={12}>
                            <Grid container spacing={3}>
                                <AlgoliaAccHits />
                            </Grid>

                            <Grid container justify={"center"}
                                  className={`${classes.paginationGrid} ${classes.relative}`}>
                                <AlgoliaPagination
                                    showPrevious={true}
                                    showNext={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xl={2} className={`${classes.hiddenLgDown} ${classes.stickySort}`}>
                            <AlgoliaSortByDesktop
                                defaultRefinement={accessoriesIndexName}
                                items={sortAccessories}
                            />
                        </Grid>
                    </InstantSearch>
                )}
            </Grid>
        )
    };
}

function mapStateToProps(state) {
    return {
        location: state.router.location,
        facets: state.cars.facets.data,
    }
}

AccessoryListing = withRouter(AccessoryListing);
AccessoryListing = withStyles(customStyles)(AccessoryListing);
AccessoryListing = connect(mapStateToProps, {...actions})(AccessoryListing);

export default AccessoryListing;
