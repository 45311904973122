import React, {useState} from "react";
import {Link as RouterLink} from 'react-router-dom';
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _map from "lodash/map";
import clsx from "clsx";
import Fade from "react-reveal/Fade";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import toLocale from "../../helpers/toLocale";
import SpaceBox from "../SpaceBox";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Slider from "./Slider";
import {SwiperSlide} from 'swiper/react';
import {makeStyles} from "@material-ui/core/styles";
import {PAGES_SLUGS} from "../../constants";
import {Breadcrumb} from "../Breadcrumb";
import HeaderOfferObserver from "../HeaderOfferObserver";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import Container from "@material-ui/core/Container";
import ReactHtmlParser from "react-html-parser";
import _size from "lodash/size";
import itemDetailsStyles from "../../theme/itemDetailsStyles";
import {getWishlistData, WL} from "../../helpers/localStorage";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {addCarToWishlist, removeCarFromWishlist} from "../../containers/WishlistPage/actions";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {appStyle} from "../../theme/appStyle";
import CountdownBlueWeeks from "../BlueWeeks/Countdown";

const ProductHeroSliderBlueWeeks = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {data} = props;
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [open, setOpen] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);
    const updatingWishlist = useSelector(state => state.wishlist.request);

    const archived = _get(data, "archived", false);
    const showEcoBonus = _get(data, "ecobonus", false) &&  !archived;
    const showElectricEcoBonus = _get(data, "ecobonus_bev", false) &&  !archived;

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenRemove(false);
    };

    const addToWishlist = () => {
        setOpenRemove(false);
        dispatch(addCarToWishlist(data.slug));
        setOpen(true);
    }

    const removeFromWishlist = () => {
        setOpen(false);
        dispatch(removeCarFromWishlist(data.slug));
        setOpenRemove(true);
    }

    const renderEcobonus = () => {
        /**
         * ecoBonusData[0] is assigned to hybrid cars
         * ecoBonusData[1] is assigned to electric cars
         */
        const ecoBonusData = _get(props.pageData, "data.ecobonus_section.ecobonus_section", []);

        if (!_isEmpty(ecoBonusData[0]) && showEcoBonus) {
            return ReactHtmlParser(_get(ecoBonusData[0], "attributes.text", ""));
        }

        if (!_isEmpty(ecoBonusData[1]) && showElectricEcoBonus) {
            return ReactHtmlParser(_get(ecoBonusData[1], "attributes.text", ""));
        }

        return null
    }

    const showDiscountPrice = Boolean(data.client_invoice_price) && data.client_invoice_price !== data.total_car_price;

    return (
        <React.Fragment>
            <Snackbar open={open} onClose={handleClose}
                      autoHideDuration={2000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      className={classes.customSnackbar}
            >
                <Alert
                    onClose={handleClose}
                    action={''}
                    icon={false}
                    className={`${classes.customAlert} ${classes.alertSuccess}`}
                >
                    ✓ Adăugat cu succes in wish list
                </Alert>
            </Snackbar>
            <Snackbar open={openRemove} onClose={handleClose}
                      autoHideDuration={2000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      className={classes.customSnackbar}
            >
                <Alert
                    onClose={handleClose}
                    action={''}
                    icon={false}
                    className={`${classes.customAlert} ${classes.alertSuccess}`}
                >
                    <Icon className={clsx("brand-icon-remove")}/> Șters cu succes in wish list
                </Alert>
            </Snackbar>
            <Fade>
                <Grid container className={classes.root}>
                    <Grid item lg={7} xs={12}
                          className={classes.darkGrey}
                    >
                        <Box
                            height={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"space-between"}
                        >
                            <Box display={"block"}>
                                <Slider
                                    effect="fade"
                                    thumbs={{swiper: thumbsSwiper}}
                                >
                                    {!_isEmpty(_get(data, "photos", [])) && _map(_get(data, "photos", []), (item, index) => {
                                        return <SwiperSlide key={index}>
                                            <div className={clsx(classes.slide)}>
                                                <img
                                                    // Remove lazy load support due to browser POP bug
                                                    src={getSuppotedFileType(_get(item, "big", []))}
                                                    className={clsx(classes.image)}/>
                                            </div>
                                        </SwiperSlide>
                                    })}
                                </Slider>
                            </Box>
                            <Box display={"block"} className={classes.thumbRoot}>
                                <Slider
                                    onSwiper={(slider) => {
                                        setThumbsSwiper(slider);
                                    }}
                                    spaceBetween={8}
                                    freeMode={true}
                                    slidesPerView={4}
                                    watchSlidesVisibility={true}
                                    watchSlidesProgress={true}
                                    breakpoints={{
                                        500: {
                                            spaceBetween: 16,
                                            slidesPerView: 5
                                        },
                                    }}
                                >
                                    {!_isEmpty(_get(data, "photos", [])) && _map(_get(data, "photos", []), (item, index) => {
                                        return <SwiperSlide key={index}>
                                            <div className={classes.thumbSlide}>
                                                <img src={getSuppotedFileType(_get(item, "thumb", []))}
                                                     className={clsx(classes.thumbImage)}/>
                                            </div>
                                        </SwiperSlide>
                                    })}

                                </Slider>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item lg={5} xs={12} className={classes.growArea}>
                        <Breadcrumb buttonName={'Modele'} name={_get(data, 'model', '')}/>
                        <div className={classes.headerContent}>
                            <Typography
                                variant="h1"
                                component="h1"
                                className={classes.title}
                            >
                                {_get(data, 'name', '')}
                            </Typography>
                            {/*<Hidden smDown>*/}
                            <Typography
                                className={classes.sku}
                                component="p"
                            >
                                # {_get(data, 'rom_ref', '')}
                            </Typography>

                            <div className={`${classes.timerBox}`}>
                            <Box flexGrow={1} bgcolor="primary.dark" pt={3} px={3}>
                                {archived
                                    ? <Typography
                                        component="div"
                                        className={`${classes.text}`}>
                                        Ne pare rău! Mașină nu mai este disponibilă in oferta Volvo.
                                    </Typography>

                                    : <Box display={"flex"}
                                         alignItems={"center"}
                                           pb={3}
                                    >
                                        <Box>
                                            <span className={`${classes.price} ${classes.whiteText}`}>
                                                {toLocale(_get(data, 'client_invoice_price', ''))}€ + TVA
                                            </span>
                                            {showDiscountPrice &&
                                                <div className={`${classes.oldPrice} ${classes.colorBlueGrey}`}>
                                                    <span className={`${classes.oldPriceLineThrough}`}>
                                                        {toLocale(_get(data, 'total_car_price', ''))}€
                                                    </span> + TVA
                                            </div>}
                                        </Box>

                                        <Box display={"flex"} flexDirection={"column"}>
                                            {_get(data, "discount_percentage", false) && <div>
                                                <div className={`${classes.discountChip}`}>
                                                    -{toLocale(data.discount_percentage)}%
                                                </div>
                                            </div>}
                                            <div className={`${classes.blueChip}`}>Blue Weeks</div>
                                        </Box>
                                    </Box>
                                }

                                {((showEcoBonus || showElectricEcoBonus) && _size(props.pageData) > 0 ) && <Typography
                                    component="div"
                                    className={`${classes.text} ${_get(data, "rezervat", true) ? classes.bottomSpacing : ''}`}>
                                    {renderEcobonus()}
                                </Typography>}

                                {(_get(data, "rezervat", false) && !archived) && <Typography
                                    component="div"
                                    className={classes.text}>
                                    Această mașină este rezervată.
                                </Typography>}
                                <SpaceBox/>
                                {!archived ?
                                    <Button
                                        component={RouterLink}
                                        variant="contained"
                                        classes={{root: `${classes.button} ${classes.buttonMargin}`}}
                                        to={`/${PAGES_SLUGS.OFFERS}/${_get(data, 'slug', '')}`}
                                    >
                                        Cere ofertă
                                    </Button>
                                    :
                                    <Button
                                        component={RouterLink}
                                        variant="contained"
                                        classes={{root: `${classes.button} ${classes.buttonMargin}`}}
                                        to={`/${PAGES_SLUGS.PRODUCTS}`}
                                    >
                                        Vezi modele similare
                                    </Button>
                                }
                                {!archived &&
                                    <React.Fragment>
                                        {getWishlistData(WL.CAR).includes(_get(data, 'slug', ''))
                                            ? <Button
                                                variant="outlined"
                                                size="large"
                                                classes={{root: `${classes.wishlistButton} ${classes.buttonMargin}`}}
                                                startIcon={<Icon className="brand-icon-heart-fill"/>}
                                                onClick={removeFromWishlist}
                                                disabled={updatingWishlist}
                                            >
                                                Șterge din wish list
                                                {updatingWishlist &&
                                                <CircularProgress size={24} className={`${classes.buttonProgress} ${classes.whiteText}`}/>}
                                            </Button>
                                            : <Button
                                                variant="outlined"
                                                size="large"
                                                classes={{root: `${classes.wishlistButton} ${classes.buttonMargin}`}}
                                                startIcon={<Icon className="brand-icon-heart"/>}
                                                onClick={addToWishlist}
                                                disabled={updatingWishlist}
                                            >
                                                Wish list
                                                {updatingWishlist &&
                                                    <CircularProgress size={24} className={`${classes.buttonProgress} ${classes.whiteText}`}/>}
                                            </Button>
                                        }
                                    </React.Fragment>
                                }
                            </Box>

                            <Box className={`${classes.countdownContainer}`} bgcolor="primary.dark" p={3}>
                                <Typography variant={"h1"}
                                            className={`${classes.weight500} ${classes.whiteText}`}>
                                    Grăbește-te să prinzi oferta!
                                </Typography>
                                <Box width={"100%"} pt={3}></Box>
                                <Grid container>
                                    <CountdownBlueWeeks/>
                                </Grid>
                                <Box width={"100%"} pt={3}></Box>
                                <Typography variant={"subtitle2"}
                                            className={`${classes.weight300} ${classes.pageSubtitle} ${classes.whiteText}`}>
                                    Livrare imediată <span className={classes.sidePadding}>/</span> 4 ani garanție
                                </Typography>
                            </Box>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Fade>

            <HeaderOfferObserver className={`${classes.offerHeader}`} stickyClass={classes.showHeader}>
                <div className={classes.headerRoot}>
                    <Container>
                        <div className={classes.headerSizing}>
                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                height={"100%"}
                                className={classes.mobileHeaderWrapper}
                            >
                                <Typography
                                    className={`${classes.headerTitle} ${classes.whiteText}`}
                                >
                                    {_get(data, 'name', '')}
                                </Typography>
                                <Box display={"flex"}
                                     alignItems={"center"}
                                     className={classes.mobileTextWrapper}>
                                    {!archived &&
                                        <React.Fragment>
                                            <Box display={"flex"} className={classes.mobilePricesWrapper}>
                                                <span className={`${classes.price} ${classes.whiteText} ${classes.headerPrice}`}>
                                                    {`${toLocale(_get(data, 'client_invoice_price', ''))}€ + TVA`}
                                                </span>
                                                {showDiscountPrice &&
                                                    <div className={`${classes.oldPrice} ${classes.colorBlueGrey} ${classes.headerOldPrice}`}>
                                                         <span className={classes.oldPriceLineThrough}>
                                                             {toLocale(_get(data, 'total_car_price', ''))}€
                                                         </span> + TVA
                                                    </div>
                                                }
                                            </Box>
                                            <Button
                                                component={RouterLink}
                                                variant="contained"
                                                classes={{root: `${classes.button} ${classes.headerButton}`}}
                                                to={`/${PAGES_SLUGS.OFFERS}/${_get(data, 'slug', '')}`}
                                            >
                                                Cere ofertă
                                            </Button>
                                        </React.Fragment>
                                    }
                                </Box>
                            </Box>
                        </div>
                    </Container>
                </div>

            </HeaderOfferObserver>
        </React.Fragment>
    )
}

const offerHeaderTransition = 0.2;

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...itemDetailsStyles(theme),
    root: {
        [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(3),
        },
    },
    darkGrey: {
        background: "#585859",
        overflow: "hidden",
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        [theme.breakpoints.up('lg')]: {
            borderBottomLeftRadius: 0,
            borderTopRightRadius: 4,
        }
    },
    slide: {
        position: "relative",
        paddingTop: "56.28%",
        width: "100%",
    },

    image: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
    },
    thumbRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },

    thumbSlide: {
        width: "100%",
        paddingTop: "56.17%",
        position: "relative",
        cursor: "pointer",
    },

    thumbImage: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        opacity: 0.4,
        transition: theme.transitions.create(['opacity'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        ".swiper-slide-thumb-active &": {
            opacity: 1,
        },
    },
    header: {
        position: "relative",
    },
    growArea: {
        display: "flex",
        flexDirection: "column",
    },
    timerBox: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        [theme.breakpoints.up('lg')]: {
            borderRadius: 4,
        }
    },
    headerContent: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    button: {
        backgroundColor: "#fff",
        color: theme.palette.primary.dark,
        marginRight: theme.spacing(3)
    },
    wishlistButton: {
        borderColor: "#fff",
        color: "#fff",
    },
    buttonMargin: {
        marginBottom: theme.spacing(3),
    },
    title: {
        letterSpacing: 0,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.pxToRem(36),
        lineHeight: theme.typography.pxToRem(48),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(24),
            lineHeight: theme.typography.pxToRem(32),
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    sku: {
        color: '#707070',
        fontSize: '14px',
        fontWeight: '300',
        letterSpacing: 0,
        lineHeight: '24px',
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
    text: {
        color: '#fff',
        letterSpacing: 0,
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(24),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(16),
        },
        "&$bottomSpacing": {
            marginBottom: theme.spacing(2),
        }
    },
    bottomSpacing: {/*Empty declaration for nested declaration*/},
    price: {
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: `1.375rem`,
    },
    oldPrice: {
        paddingTop: theme.spacing(1),
        fontSize: `1.125rem`,
    },
    oldPriceLineThrough: {
        textDecoration: "line-through",
    },
    blueChip: {
        ...appStyle(theme).chipBase,
        background: `#2F5790`,
    },
    discountChip: {
        ...appStyle(theme).chipBase,
        background: `#9E2B2B`,
        marginBottom: theme.spacing(1),
    },
    countdownContainer: {
        backgroundColor: `#202A44`
    },
    pageSubtitle: {
        ...appStyle(theme).pageSubtitle,
        marginBottom: 0
    },
    headerRoot: {
        background: theme.palette.primary.dark,
    },
    headerSizing: {
        height: 100,
    },
    offerHeader: {
        zIndex: 1101,
        top: 0,
        opacity: 0,
        visibility: `hidden`,
        width: `100%`,
        position: `fixed`,
        transition: `opacity ${offerHeaderTransition}s 0s, visibility 0s ${offerHeaderTransition}s`
    },
    showHeader: {
        opacity: 1,
        visibility: `visible`,
        transition: `opacity ${offerHeaderTransition}s, visibility ${offerHeaderTransition}s 0s`
    },
    headerTitle: {
        fontSize: `1.125rem`,
        fontWeight: 500,
        lineHeight: `1.5rem`,
        overflow: `hidden`,
        textOverflow: `ellipsis`,
        display: `-webkit-box`,
        lineClamp: 3,
        boxOrient: `vertical`,
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(20),
        },
    },
    headerPrice: {
        textAlign: `center`,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            textAlign: "left",
            marginRight: theme.spacing(0),
            fontSize: theme.typography.pxToRem(18),
            lineHeight: theme.typography.pxToRem(24),

        },
    },
    headerOldPrice: {
        marginRight: theme.spacing(5),
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(2),
            fontSize: theme.typography.pxToRem(14),
            lineHeight: theme.typography.pxToRem(24),
        },
    },
    headerButtonText: {},
    headerButton: {
        textAlign: `center`,
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.pxToRem(12),
            lineHeight: theme.typography.pxToRem(20),
            padding: theme.spacing(1)
        },
    },

    mobileHeaderWrapper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column`,
            justifyContent: `space-evenly`,
            alignItems: `flex-start`,
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        }
    },
    mobileTextWrapper: {

        [theme.breakpoints.down('sm')]: {
            alignItems: `center`,
            alignSelf: `stretch`,
            justifyContent: `space-between`,
        }
    },
    mobilePricesWrapper: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column`
        },
        [theme.breakpoints.up('md')]: {
            alignItems: `center`
        }
    }
}));

export default ProductHeroSliderBlueWeeks
