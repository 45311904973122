import React, {Component} from 'react';
import {connectSortBy} from "react-instantsearch-dom";
/*UI*/
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Popover from '@material-ui/core/Popover';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {indexName} from "../../../constants/algolia";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import RadioGroup from "@material-ui/core/RadioGroup";
import filterStyles from "../../../theme/filterStyles";

const customStyles = theme => ({
    ...filterStyles(theme),
    sortWidth: {
        // The margins from the grid spacing
        [theme.breakpoints.down('sm')]: {
            width: `calc(50vw - ${theme.spacing(6)})`
        },
        width: 210
    },
    popoverPaper: {
        border: `1px solid #eee`,
        borderTopWidth: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        padding: theme.spacing(2)
    },
    /// Duplicate declarations with Desktop search
    sortType: {
        marginTop: theme.spacing(4)
    },
    topMarginDivider: {
        marginTop: theme.spacing(1)
    },
    label: {
        margin: 0
    },
    smallMobileLabel: {
        '@media (max-width: 460px)': {
            fontSize: `0.8rem`
        },
    }
});

class AlgoliaSortByMobile extends Component {
    state = {
        sortAnchor: null,
        value: this.props.items.find(item => item.isRefined).value,
        priceSortArray: [],
        discountSortArray: [],
    };

    componentDidMount() {
        const {items} = this.props;
        items.map(item => {
            if (item.value.includes('price')) this.state.priceSortArray.push(item)
            if (item.value.includes('discount')) this.state.discountSortArray.push(item)
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let newValue = this.props.items.find(item => item.isRefined).value;
        if (prevState.value != newValue) {
            this.setState({value: newValue});
        }
    }

    handleRefineChange = (event) => {
        const selectedSort = event.target.value;
        console.log(selectedSort);
        this.setState({
            value: selectedSort
        }, () => {
            this.props.refine(selectedSort)
            this.closeSortMenu();
        });
    };

    openSortMenu = event => {
        this.setState({
            sortAnchor: event.currentTarget,
        });
    };

    closeSortMenu = () => {
        this.setState({
            sortAnchor: null,
        });
    };

    renderFormControls = (item) => {
        const {classes} = this.props;
        return (
            <FormControlLabel
                className={classes.label}
                classes={{label: classes.smallMobileLabel}}
                label={item.label}
                key={item.value}
                value={item.value}
                control={
                    <Radio
                        color={'primary'}
                        checkedIcon={<div className={`${classes.rounded} ${classes.customCheckbox}`}> </div>}
                        icon={<div className={`${classes.rounded} ${classes.emptyCustomCheckbox}`}> </div>}
                    />}
            />
        )
    }

    render() {
        const {classes, defaultRefinement} = this.props;

        return (
            <React.Fragment>
                <Button
                    size="small"
                    variant="outlined"
                    aria-describedby="sortToggle"
                    onClick={this.openSortMenu}
                    className={`${classes.sortButton} ${classes.sortWidth} ${Boolean(this.state.sortAnchor) ? classes.openSortButtonStyle : ''}`}
                >
                    <Typography variant={"body1"} className={`${classes.weight300} ${classes.sortButtonText}`}>
                        <Icon className={`${classes.iconRightMargin} brand-icon-sort`}/>
                        Sortează
                    </Typography>
                    <Icon className={`${classes.sortIcon} brand-icon-down-arrow`} color={"primary"}/>
                </Button>

                <Popover
                    PaperProps={{
                        elevation: 0,
                        classes:{root: `${classes.sortWidth} ${classes.popoverPaper}`}
                    }}
                    id='sortToggle'
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(this.state.sortAnchor)}
                    anchorEl={this.state.sortAnchor}
                    onClose={this.closeSortMenu}
                >
                    {/*Using a common widget for mobile and desktop somehow slows down the mobile dropdown a lot*/}
                    {/*and it ends up yielding errors: state.value not found in the widget*/}
                    {/*Keeping it for references*/}
                    {/*<AlgoliaSortByContent items={items} defaultRefinement={defaultRefinement}/>*/}

                    <RadioGroup aria-label="sort-mobile" name="sort-mobile" value={this.state.value}
                                onChange={this.handleRefineChange}>
                        <FormControlLabel
                            value={defaultRefinement}
                            label={'Predefinit'}
                            className={classes.label}
                            control={
                                <Radio color={'primary'}
                                       checkedIcon={<div className={`${classes.rounded} ${classes.customCheckbox}`}> </div>}
                                       icon={<div className={`${classes.rounded} ${classes.emptyCustomCheckbox}`}> </div>}
                                />}
                        />
                        <Typography className={classes.sortType}>
                            <strong>Preț</strong>
                        </Typography>
                        {this.state.priceSortArray.map(item => {
                            return this.renderFormControls(item);
                        })}
                        <Divider className={classes.topMarginDivider}/>
                        <Typography className={classes.sortType}>
                            <strong>Discount %</strong>
                        </Typography>
                        {this.state.discountSortArray.map(item => {
                            return this.renderFormControls(item);
                        })}
                    </RadioGroup>
                </Popover>
            </React.Fragment>
        );
    }
}

AlgoliaSortByMobile = withStyles(customStyles)(AlgoliaSortByMobile);
AlgoliaSortByMobile = connectSortBy(AlgoliaSortByMobile);
export default AlgoliaSortByMobile;
