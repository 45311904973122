import React, {useEffect} from 'react'
import clsx from "clsx";
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from "react-router";
import Fade from "react-reveal/Fade";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";

import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import {fetchPage} from "../../actions/pages"
import {fetchAccessory} from "../AccessoryDetailsPage/actions"
import SectionBox from "../../components/SectionBox";
import ProgressFullScreen from "../../components/ProgressFullScreen";
import Icon from "@material-ui/core/Icon";
import toLocale from "../../helpers/toLocale";
import {PAGES_SLUGS} from "../../constants";
import ReactHtmlParser from "react-html-parser";
import Seo from "../../components/seo/Seo";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {appStyle} from "../../theme/appStyle";
import itemDetailsStyles from "../../theme/itemDetailsStyles";
import {addSlug, getAccWishlistFromUuid} from "../WishlistPage/actions";
import LazyImage from "../../components/LazyImage";
import GetAccessoryOfferForm from "../../components/forms/GetAccessoryOfferForm";

export const parameterType = {
    SLUG: 'SLUG',
    UUID: 'UUID'
}

function AccessoryGetOfferPage(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {urlParameter} = useParams();

    const slugArray = useSelector(state => state.wishlist.offersSlugArray);
    const idArray = useSelector(state => {
        if (_isEmpty(state.wishlist.offersSlugArray)) return [];

        return state.wishlist.offersSlugArray.map((slug) => {
            return slug.substring(slug.lastIndexOf('-') + 1);
        })
    });
    const loading = useSelector(state => state.wishlist.request);
    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.OFFERS, {}));
    const pageDataLoading = useSelector(state => state.pages.request);
    // console.log(idArray);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (props.parameterType === parameterType.SLUG) {
            dispatch(addSlug(urlParameter))
        } else if (props.parameterType === parameterType.UUID) {
            dispatch(getAccWishlistFromUuid(urlParameter))
        }
    }, [])

    useEffect(() => {
        if (_isEmpty(pageData)) {
            dispatch(fetchPage(PAGES_SLUGS.OFFERS))
        }
    }, [])

    // console.log(slugArray);
    // generateSlugsFrom(['base_name', 'variant_name', 'external_cab_variant_id'])
    return (
        <React.Fragment>
            {!_isEmpty(_get(pageData, 'data.meta_data', {})) && <Seo data={pageData.data.meta_data}/>}
            {!_isEmpty(slugArray) && !pageDataLoading && <>
                {!_isEmpty(_get(pageData, 'data.content', {})) && <Fade>
                    <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        className={classes.banner}
                        style={{
                            "--background-image": `url(${_get(pageData, 'data.content[0].attributes.side_image', "")})`
                        }}
                    >
                        <Fade delay={1000}>
                            <Typography component={'div'}
                                        className={classes.bannerTitle}>{ReactHtmlParser(_get(pageData, "data.content[0].attributes.title", ''))}</Typography>

                            <Typography align={"center"} component={'div'}
                                        className={classes.bannerText}>{ReactHtmlParser(_get(pageData, "data.content[0].attributes.content", ''))}</Typography>
                        </Fade>
                    </Box>
                </Fade>}
                <SectionBox>
                    <Container>
                        <Grid container>
                            <Grid item sm={6} xs={12}>
                                <Box
                                    display={"flex"}
                                    justifyContent={"flex-start"}
                                    alignItems={"center"}
                                >
                                    <Icon className={clsx("brand-icon-car", classes.sectionIcon)}/>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        className={classes.sectionTitle}
                                    >
                                        {props.parameterType === parameterType.SLUG
                                            ? `1. Accesoriul selectat`
                                            : `1. Accesoriile selectate`
                                        }
                                    </Typography>
                                </Box>
                                <Grid container className={classes.productCardRoot}>
                                    {slugArray.map((slug, index) => <AccessoryOfferCard key={index} slug={slug}/>)}
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <GetAccessoryOfferForm
                                    idArray={idArray}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </SectionBox>
            </>}
            {loading &&
            <ProgressFullScreen loading={loading}/>
            }
        </React.Fragment>
    )
}

/**
 * Slugs are composed this way:
 * generateSlugsFrom(['base_name', 'variant_name', 'external_cab_variant_id'])
 * We can get safely get the ID from them
 */
function AccessoryOfferCard(props) {
    const {slug} = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const accessory = useSelector(state => _get(state.accessoryDetails.data, slug, {}));
    const loading = useSelector(state => state.accessoryDetails.request);

    useEffect(() => {
        if (_isEmpty(accessory)) {
            dispatch(fetchAccessory(slug))
        }
    }, [])

    return (
        loading
            ? <ProgressFullScreen loading={loading}/>
            : <Grid container spacing={2} className={classes.cardMargin}>
                <Grid item xs={12} lg={5}>
                    <LazyImage src={getSuppotedFileType(_get(accessory, "photos[0].thumb", []))} ratio={56}
                               isAnimated={true} alt={_get(accessory, 'base_name', '')}/>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Box display={"flex"} justifyContent={"space-between"} flexDirection="column" height="100%">
                        <Typography className={classes.sku} component="p">
                            # {_get(accessory, 'external_cab_variant_id', '')}
                        </Typography>

                        <Typography variant="h2" className={classes.title}>
                            {_get(accessory, 'base_name', '')}
                        </Typography>

                        <Typography component="p" className={classes.category}>
                            {_get(accessory, 'categorie', '')} / {_get(accessory, 'subcategorie', '')}
                        </Typography>

                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                            <Typography
                                component={"div"}
                                className={classes.rootPrice}>
                                Preț final: <span className={classes.price}>
                                        {toLocale(_get(accessory, 'final_price', ''))}€ + TVA
                                    </span>
                                {accessory.final_price !== accessory.base_price && <div className={classes.oldPrice}>
                                    Redus de la: <span
                                    className={classes.oldPriceLineThrough}>{toLocale(_get(accessory, 'base_price', ''))}€</span> +
                                    TVA (-{`${toLocale(_get(accessory, "discount_percentage", ""))}`}%)
                                </div>}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography component={"p"} className={classes.shortDescription}>{accessory.short_description}</Typography>
                </Grid>
            </Grid>
    )
}


const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...itemDetailsStyles(theme),
    banner: {
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.35), rgba(0,0,0,0.35)), var(--background-image)`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        minHeight: 540,
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            minHeight: 350,
        },
    },
    bannerTitle: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(38),
        lineHeight: theme.typography.pxToRem(56),
        letterSpacing: "0.4",
        color: "#fff",
    },
    bannerText: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        color: "#fff",
    },
    sectionTitle: {
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    productCardRoot: {
        backgroundColor: "#FAFAFA",
        padding: theme.spacing(1),
        flex: 1,
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(3),
        },
    },
    cardMargin: {
        marginBottom: theme.spacing(4)
    },
    sku: {
        color: '#707070',
        fontWeight: '300',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
        paddingBottom: theme.spacing(1),
    },
    category: {
        color: '#707070',
        fontWeight: '300',
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
        paddingBottom: theme.spacing(1),
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    shortDescription: {
        fontWeight: '300',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: `0.37px`,
    },
    rootPrice: {
        ...itemDetailsStyles(theme).rootPrice,
        paddingBottom: 0
    }
}))

export default AccessoryGetOfferPage;
