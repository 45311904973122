import React, {useEffect, useState} from 'react'
import ScrollAgent from 'react-scroll-agent';
import {useInView} from 'react-intersection-observer';
import {Sticky, StickyContainer} from 'react-sticky';
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from "react-router";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _map from "lodash/map";
import _filter from "lodash/filter";
import clsx from "clsx";
import _slice from "lodash/slice";
import {Collapse} from "react-collapse";
import ReactHtmlParser from "react-html-parser";
import {useHistory} from "react-router-dom";

import * as actions from "../../actions/products"
import {fetchPage} from "../../actions/pages"
import {makeStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Link from "@material-ui/core/Link";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";

import {addCarToWishlist, removeCarFromWishlist} from "../WishlistPage/actions";
import {getWishlistData, WL} from "../../helpers/localStorage";
import getSuppotedFileType from "../../helpers/getSuppotedFileType";
import {PAGES_SLUGS} from "../../constants";
import {ShareUrl} from "../../helpers/shareURL";
import {availabilityOffset, getYearAndWeek} from "../../helpers/inStock";

import itemDetailsStyles from "../../theme/itemDetailsStyles";
import {appStyle} from "../../theme/appStyle";
import SectionBox from "../../components/SectionBox";
import Fade from "react-reveal/Fade";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {Title} from "../../components/Typografy";
import TabsWidget from "../../components/TabsWidget";
import OverviewCard from "../../components/OverviewCard";
import ExpandableSections from "../../components/ExpandableSections";
import PacheteCard from "../../components/PacheteCard";
import OptionsCardWidthPhoto from "../../components/OptionsCardWidthPhoto";
import OptionsDataWidget from "../../components/OptionsDataWidget";
import ProgressFullScreen from "../../components/ProgressFullScreen";
import TehnicalDataCard from "../../components/TehnicalDataCard";
import RelatedProducts from "../../components/sliders/RelatedProducts";
import ProductHeroSlider from "../../components/sliders/ProductHeroSlider";
import ProductHeroSliderBlueWeeks from "../../components/sliders/ProductHeroSliderBlueWeeks";
import Seo from "../../components/seo/Seo";
import Footnotes from "../../components/Footnotes";
import StandardEqSectionWidget from "../../components/DetailsPage/StandardEqSectionWidget";
import GetOfferSliderParent from "../GetOfferPage/GetOfferSliderParent";
import {_endDate} from "../../components/BlueWeeks/Countdown";

function DetailsPage(props) {
    const dispatch = useDispatch();
    const {slug} = useParams();
    const classes = useStyles();
    const history = useHistory();

    const product = useSelector(state => _get(state.products.data, slug, {}));
    const loading = useSelector(state => state.products.request);
    const error = useSelector(state => _get(state.products.error, slug, {}));
    const isDealerPage = useSelector(state => state.dealer.data);
    const pageData = useSelector(state => _get(state.pages.data, PAGES_SLUGS.CAR_DETAILS, {}));
    const {ref, inView, entry} = useInView({
        /* Optional options */
        threshold: 0,
    });
    const updatingWishlist = useSelector(state => state.wishlist.request);
    const [open, setOpen] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);

    const archived = _get(product, "archived", false);
    const showEcoBonus = _get(product, "ecobonus", false) &&  !archived;
    const showElectricEcoBonus = _get(product, "ecobonus_bev", false) &&  !archived;
    const hasEcoDisclaimer = showEcoBonus || showElectricEcoBonus;
    const hasRegularDisclaimer =  _get(pageData, "data.disclaimer_section.disclaimer_section", false) &&  !archived;

    const promoEnded = (_endDate - new Date().getTime()) < 1000;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (_isEmpty(product)) {
            dispatch(actions.fetchProduct(slug))
        }
    }, [slug])

    useEffect(() => {
        const errorResponse = _get(error, "errorResponse", null);
        if (errorResponse === 404) {
            history.push(`/`);
        }
    }, [error])


    useEffect(() => {
        if (_isEmpty(pageData)) {
            dispatch(fetchPage(PAGES_SLUGS.CAR_DETAILS))
        }
    }, [])

    useEffect(() => {
        // Track the initial page view
        if (window.snowplow && !_isEmpty(product)) {
            window.snowplow('trackSelfDescribingEvent', {
                event: {
                    schema: 'iglu:com.exemplu/car_info/jsonschema/1-0-0',
                    data: {
                        brand: _get(product, "brand", ''),
                        car_body: _get(product, "car_body", ''),
                        configuration_id: _get(product, "configuration_id", ''),
                        engine: _get(product, "engine", ''),
                        fuel: _get(product, "fuel", ''),
                        model: _get(product, "model", ''),
                        name: _get(product, "name", ''),
                        nr_seats: _get(product, "nr_seats", ''),
                        rom_ref: _get(product, "rom_ref", ''),
                        short_name: _get(product, "short_name", ''),
                        standard_euro_emissions: _get(product, "standard_euro_emissions", ''),
                        transmission: _get(product, "transmission", ''),
                        transmission_type: _get(product, "transmission_type", ''),
                    }
                }
            });
        }
    }, [product]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpenRemove(false);
    };

    const addToWishlist = () => {
        setOpenRemove(false);
        dispatch(addCarToWishlist(slug));
        setOpen(true);
    }

    const removeFromWishlist = () => {
        setOpen(false);
        dispatch(removeCarFromWishlist(slug));
        setOpenRemove(true);
    }

    const renderFootnotes = () => {
        /**
         * ecoBonusData[0] is assigned to hybrid cars
         * ecoBonusData[1] is assigned to electric cars
         */
        const ecoBonusData = _get(pageData, "data.ecobonus_section.ecobonus_section", []);

        if (!_isEmpty(ecoBonusData[0]) && showEcoBonus) {
            return <Typography gutterBottom variant="caption" component={"div"}>
                {ReactHtmlParser(_get(ecoBonusData[0], "attributes.notes", ""))}
            </Typography>
        }

        if (!_isEmpty(ecoBonusData[1]) && showElectricEcoBonus) {
            return <Typography gutterBottom variant="caption" component={"div"}>
                {ReactHtmlParser(_get(ecoBonusData[1], "attributes.notes", ""))}
            </Typography>
        }

        return null
    }

    const renderDisclaimerNotes = () => {
        const disclaimerData = _get(pageData, "data.disclaimer_section.disclaimer_section", []);
        if (!_isEmpty(disclaimerData)) {
            return _map(disclaimerData, (item, index) => {
                return <Typography key={index} gutterBottom variant="caption" component={"div"}>
                    {ReactHtmlParser(_get(item, "attributes.text", ""))}
                </Typography>
            })
        }

        return null
    }
    const handleGoTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        // bannerRef.current.scrollIntoView({
        //     behavior: 'smooth',
        //     block:"start"
        // })

    }

    let overviewCount = 0;

    const setReversStatus = () => {
        if (overviewCount < 3) {
            return false
        }
        if (overviewCount == 4) {
            overviewCount = 0;
        }
        return true;
    }

    const renderOptionsCardWithPhoto = () => {
        return _map(_get(product, "tab_data.optional_equipment", []), (item, index) => {
            return _map(item.data, (option, idx) => {
                if (!_isEmpty(_get(option, "photos", []))) {
                    return <OptionsCardWidthPhoto
                        key={idx}
                        data={option}
                    />
                }
            })
        })
    }

    const renderOptionsCard = () => {
        let optionsDataArr = [];

        _map(_get(product, "tab_data.optional_equipment", []), (item, index) => {
            let filtered = _filter(item.data, (option) => {
                return _isEmpty(_get(option, "photos", []));
            })
            optionsDataArr = [...optionsDataArr, ...filtered];
        })

        return <OptionsDataWidget
            data={optionsDataArr}
        />
    }
    return (
        <React.Fragment>
            {!(loading || _isEmpty(product)) && <section className={clsx(classes.root, "submenuScrollarea")}>
                <Seo data={{
                    meta_og_title: _get(product, "name", ''),
                    meta_title: _get(product, "name", ''),
                    meta_image: _get(product, "photos[0].thumb[1]", ''),
                }}/>

                <Snackbar open={open} onClose={handleClose}
                          autoHideDuration={200000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          className={classes.customLowerSnackbar}
                >
                    <Alert
                        onClose={handleClose}
                        action={''}
                        icon={false}
                        className={`${classes.customAlert} ${classes.alertSuccess}`}
                    >
                        ✓ Adăugat cu succes in wish list
                    </Alert>
                </Snackbar>
                <Snackbar open={openRemove} onClose={handleClose}
                          autoHideDuration={200000}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          className={classes.customLowerSnackbar}
                >
                    <Alert
                        onClose={handleClose}
                        action={''}
                        icon={false}
                        className={`${classes.customAlert} ${classes.alertSuccess}`}
                    >
                        <Icon className={clsx("brand-icon-remove")}/> Șters cu succes in wish list
                    </Alert>
                </Snackbar>

                <div ref={ref}>
                    {(getYearAndWeek(availabilityOffset.now.end) >= _get(product, "arrival_date_segment", 21000101) && !promoEnded)
                        ? <ProductHeroSliderBlueWeeks data={product} pageData={pageData}/>
                        : <ProductHeroSlider data={product} pageData={pageData}/>
                    }
                </div>
                <StickyContainer>
                    <ScrollAgent
                        nav={({current, positions}) => (
                            <Sticky topOffset={-100}>
                                {props => (
                                    <div style={{...props.style, top: 100, zIndex: 2, background: `#141414`}}>
                                        <TabsWidget
                                            current={current}
                                            positions={positions}
                                            labels={["Overview", "Date tehnice", "Echipament standard", "Pachete", "Echipament opțional"]}
                                        />
                                    </div>
                                )}
                            </Sticky>
                        )}
                        detectEnd={false}
                        selector="section[data-scrollspy]"
                        threshold="center"
                    >
                        <SectionBox contrast gutterBottom={true} gutterTop={false}>
                            <section id="section-1" data-scrollspy>
                                <Fade>
                                    <Container className={classes.overviewRoot}>
                                        <Grid container>
                                            {!_isEmpty(_get(product, "tab_data.overview", [])) && _map(_get(product, "tab_data.overview", []), (item, index) => {
                                                overviewCount++;
                                                return <OverviewCard
                                                    image={getSuppotedFileType(_get(item, "photos[0].thumb", []))}
                                                    title={item.name}
                                                    description={item.description}
                                                    key={index}
                                                    reverse={setReversStatus()}
                                                />
                                            })}
                                        </Grid>
                                    </Container>
                                </Fade>
                            </section>
                        </SectionBox>

                        <SectionBox gutterBottom={false}>
                            <Container>
                                {!_isEmpty(_get(product, "tab_data.technical_data", [])) &&
                                <section id="section-2" data-scrollspy>
                                    <ExpandableSections cta={"Date tehnice"}>
                                        <Fade>
                                            <TehnicalSectionWidget
                                                data={_get(product, "tab_data.technical_data", [])}/>
                                        </Fade>
                                    </ExpandableSections>
                                </section>}

                                {!_isEmpty(_get(product, "tab_data.standard_equipment", [])) &&
                                <section id="section-3" data-scrollspy>
                                    <ExpandableSections cta={"Echipament standard"}>
                                        <Fade>
                                            <StandardEqSectionWidget
                                                data={_get(product, "tab_data.standard_equipment", [])}/>
                                        </Fade>
                                    </ExpandableSections>
                                </section>}

                                {!_isEmpty(_get(product, "tab_data.pachete", [])) &&
                                <section id="section-4" data-scrollspy>
                                    <ExpandableSections cta={"Pachete"}>
                                        <Grid container spacing={3}>
                                            {_map(_get(product, "tab_data.pachete", []), (item, index) => {
                                                return <PacheteCard
                                                    key={index}
                                                    data={item}
                                                />
                                            })}
                                        </Grid>
                                    </ExpandableSections>
                                </section>}

                                {!_isEmpty(_get(product, "tab_data.optional_equipment", [])) &&
                                <section id="section-5" data-scrollspy>
                                    <ExpandableSections cta={"Echipament opțional"}>
                                        <Grid container spacing={3}>
                                            {renderOptionsCardWithPhoto()}
                                        </Grid>
                                        <Grid container spacing={3} className={classes.rootOptionsNoImage}>
                                            {renderOptionsCard()}
                                        </Grid>
                                    </ExpandableSections>
                                </section>}

                                {!(!!isDealerPage) && <Divider/>}
                            </Container>
                        </SectionBox>

                        <SectionBox gutterTop={false}>
                            <Fade>
                                <Container>
                                    <Box display={"flex"} flexWrap={"wrap"} justifyContent={"center"} alignItems={"center"}>
                                        {!archived &&
                                            <React.Fragment>
                                                {getWishlistData(WL.CAR).includes(slug)
                                                    ? <ButtonBase
                                                        focusRipple
                                                        component={Link}
                                                        className={classes.underlineLinkButton}
                                                        onClick={removeFromWishlist}
                                                        disabled={updatingWishlist}
                                                    >
                                                        <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                                            <Icon className={clsx("brand-icon-heart-fill", classes.icon)}/>
                                                            Șterge din wish list
                                                            {updatingWishlist &&
                                                            <CircularProgress size={24} className={classes.buttonProgress}/>}
                                                        </Box>
                                                    </ButtonBase>
                                                    : <ButtonBase
                                                        focusRipple
                                                        component={Link}
                                                        className={classes.underlineLinkButton}
                                                        onClick={addToWishlist}
                                                        disabled={updatingWishlist}
                                                    >
                                                        <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                                            <Icon className={clsx("brand-icon-heart", classes.icon)}/>
                                                            Wish list
                                                            {updatingWishlist &&
                                                            <CircularProgress size={24} className={classes.buttonProgress}/>}
                                                        </Box>
                                                    </ButtonBase>
                                                }
                                            </React.Fragment>
                                        }

                                        <ShareUrl
                                            title={_get(product, 'name', '')}
                                            text={'Descopera cele mai bune oferte ale momomentului pentru modelul Volvo preferat'}
                                        >
                                            <ButtonBase
                                                focusRipple
                                                component={Link}
                                                className={classes.underlineLinkButton}
                                            >
                                                <Box display={"flex"} alignItems={"center"} className={classes.buttonItem}>
                                                    <Icon className={clsx("brand-icon-share", classes.icon)}/>
                                                    Share mașină
                                                </Box>
                                            </ButtonBase>
                                        </ShareUrl>
                                    </Box>
                                    <Divider/>
                                </Container>
                            </Fade>
                        </SectionBox>

                        {/*Temp disable for blue Weeks*/}
                        {(!_isEmpty(_get(product, "similar_cars", [])) && promoEnded) && <SectionBox gutterBottom={false}>
                            {!(!!isDealerPage) && <Fade>
                                <Container maxWidth={"sm"}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Title>Mașini similare</Title>
                                        </Grid>
                                    </Grid>
                                </Container>
                                <Container>
                                    <RelatedProducts data={_get(product, "similar_cars", [])}/>
                                </Container>
                            </Fade>}
                        </SectionBox>}

                        {!promoEnded &&
                        <GetOfferSliderParent/>
                        }
                    </ScrollAgent>
                </StickyContainer>
                {(hasEcoDisclaimer || hasRegularDisclaimer) && <SectionBox gutterTop={false}>
                    <Fade>
                        <Footnotes>
                            {hasEcoDisclaimer && renderFootnotes()}
                            {hasRegularDisclaimer && renderDisclaimerNotes()}
                        </Footnotes>
                    </Fade>
                </SectionBox>}

            </section>}

            {(loading || _isEmpty(product)) &&
            <ProgressFullScreen loading={loading || _isEmpty(product)}/>
            }
            {!inView && <Fab
                aria-label={"Back to Top"}
                className={classes.fab}
                color={'secondary'}
                onClick={handleGoTop}
            >
                <ExpandLessIcon/>
            </Fab>}
        </React.Fragment>
    )
}

const TehnicalSectionWidget = (props) => {
    const classes = useStyles();
    const [isOpened, setIsOpened] = useState(false);
    const {data} = props;

    const handleToggle = (event) => {
        event.preventDefault();
        setIsOpened(!isOpened);
    }

    const renderToogleText = () => {
        if (isOpened) {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>Mai putin</span>
                <Icon className={clsx("brand-icon-up-arrow", classes.icon)}/>
            </Link>
        } else {
            return <Link href={"#"} onClick={handleToggle} className={classes.link}>
                <span className={classes.cta}>Mai mult</span>
                <Icon className={clsx("brand-icon-down-arrow", classes.icon)}/>
            </Link>
        }
    }

    const renderSampleData = () => {

        if (!_isEmpty(data)) {
            return _map(_slice([...data], 0, 2), (item, index) => {
                return <TehnicalDataCard
                    key={index}
                    name={item.name}
                    data={item.data}
                />
            })
        }
    }

    const renderColapsedData = () => {
        if (!_isEmpty(data)) {
            if (data.length > 2) {
                return <>
                    <Collapse isOpened={isOpened} theme={{collapse: classes.collapse, content: classes.content}}>
                        {_map(_slice([...data], 2, data.length), (item, index) => {
                            return <TehnicalDataCard
                                key={index}
                                name={item.name}
                                data={item.data}
                            />
                        })}
                    </Collapse>
                    <Grid item xs={12} className={classes.sectionBox}>
                        {renderToogleText()}
                    </Grid>
                </>
            }

        }
    }

    return <div>
        {renderSampleData()}
        {renderColapsedData()}
    </div>
}

const useStyles = makeStyles((theme) => ({
    ...appStyle(theme),
    ...itemDetailsStyles(theme),
    root: {
        background: "#fafafa",
    },
    overviewRoot: {
        [theme.breakpoints.up('lg')]: {
            paddingLeft: "55px",
            paddingRight: "55px"
            // width: 80,
        },
    },
    rootOptionsNoImage: {
        paddingTop: theme.spacing(6),
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
        },
    },
    link: {
        display: "inline-flex",
        alignItems: "center",
        position: 'relative',
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none",
        },
        "&:before": {
            content: `''`,
            position: "absolute",
            width: "0",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main,
            transition: theme.transitions.create(['width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        "&:hover:before": {
            content: `''`,
            position: "absolute",
            width: "100%",
            height: "1px",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            background: theme.palette.primary.main
        }
    },
    content: {
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
    },
    collapse: {
        width: "100%",
        transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    cta: {
        fontWeight: theme.typography.fontWeightLight,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(24),
    },
    icon: {
        fontSize: "14px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        color: theme.palette.primary.main,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },

}))

export default DetailsPage;
