import React, {Component} from 'react';
import _get from "lodash/get"
import {Link} from "react-router-dom";
import {connectAutoComplete} from 'react-instantsearch-dom';
import {withRouter} from "react-router";
// UI
import {withStyles} from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {appStyle} from "../../../theme/appStyle";
import TextField from '@material-ui/core/TextField';
import getSuppotedFileType from "../../../helpers/getSuppotedFileType";
import searchCarPrice from "./searchCarPrice";
import {accessoriesIndexName, indexName} from "../../../constants/algolia";
import searchAccPrice from "./searchAccPrice";
import {PAGES_SLUGS} from "../../../constants";

const customStyles = theme => ({
    ...appStyle(theme),
    searchBar: {
        '& svg': {
            color: `#000`,
            opacity: 0.4,
            margin: theme.spacing(1.5)
        }
    },
    searchResults: {
        zIndex: 999,
        position: `absolute`,
        left: 0,
        background: `#fff`,
        width: `100%`,
        boxShadow: `0 4px 6px -1px rgba(0,0,0,0.2)`,
        '&:empty': {
            display: `none`
        }
    },
    carImage: {
        maxHeight: 60,
        marginRight: theme.spacing(2)
    },
    marginTop: {
        marginTop: `auto`
    },
    flexCenter: {
        justifyContent: `center`
    },
    mobileCloseButton: {
        marginRight: theme.spacing(2)
    },
    highlightButton: {
        color: `${theme.palette.primary.main} !important`,
        opacity: `1 !important`,
        cursor: `pointer`
    },
    disabledButton: {
        pointerEvents: `none`
    },

    oldPrice: {
        textDecoration: `line-through`,
        marginLeft: theme.spacing(2),
        fontSize: `0.875rem`,
        lineHeight: 1.75
    }
});

class AlgoliaAutoComplete extends Component {
    state = {
        showList: true
    };

    goToProducts = (event) => {
        event.preventDefault();
        // if (this.props.hits.length && this.props.currentRefinement !== '') {
        //     this.props.refine('');
        //     this.props.onClose();
        //     history.push(`/products?query=${this.props.currentRefinement}`);
        // }
    };

    renderResultList = () => {
        const {hits, classes} = this.props;
        if (!hits.length) {
            return (
                <ListItem>
                    Nu am găsit rezultate
                </ListItem>
            )
        }

        /**
         * We use indexName parent prop to see where the search takes place (cars or acc page)
         * to render the proper element
         */
        return (
            hits.map(hit => {
                if (this.props.indexName === indexName) {
                    return (
                        <ListItem key={hit.rom_ref}
                                  button
                                  component={Link}
                                  to={{pathname: `/${PAGES_SLUGS.DETAILS}/${hit.slug}`, state: {from: this.props.location.pathname}}}
                                  onClick={this.closeDesktopSearch}>
                            <ListItemAvatar>
                                <img className={classes.carImage}
                                     src={getSuppotedFileType(_get(hit, "media[0].thumb", []))} alt={hit.name}/>
                            </ListItemAvatar>
                            <ListItemText primary={hit.name} secondary={searchCarPrice(hit, classes)}/>
                        </ListItem>
                    )
                } else if (this.props.indexName === accessoriesIndexName) {
                    return(
                        <ListItem key={hit.slug}
                                  button component={Link}
                                  to={{pathname: `/${PAGES_SLUGS.ACCESSORY_DETAIL}/${hit.slug}`, state: {from: this.props.location.pathname}}}
                                  onClick={this.closeDesktopSearch}>
                            <ListItemAvatar>
                                <img className={classes.carImage} src={getSuppotedFileType(_get(hit, "media[0].thumb", []))} alt={_get(hit,"base_name","")}/>
                            </ListItemAvatar>
                            <ListItemText primary={_get(hit,"base_name","")} secondary={searchAccPrice(hit, classes)}/>
                        </ListItem>
                    )
                }
            })
        )
    };

    closeDesktopSearch = () => {
        this.setState({showList: false})
    };

    openDesktopSearch = () => {
        this.setState({showList: true})
    };

    renderAutocompleteDesktop = () => {
        const {currentRefinement, refine, classes} = this.props;
        return (
            <ClickAwayListener onClickAway={this.closeDesktopSearch}>
                <div>
                    <form onSubmit={this.goToProducts} noValidate action="" role="search">
                        <TextField placeholder="Caută …"
                                   fullWidth
                                   variant="outlined"
                                   className={`${classes.searchBar}`}
                                   value={currentRefinement}
                                   onFocus={this.openDesktopSearch}
                                   onChange={event => refine(event.currentTarget.value)}
                                   InputProps={{
                                       margin: 'dense',
                                       startAdornment: <Search/>,
                                       endAdornment: currentRefinement !== '' &&
                                           <Close className={classes.pointer} onClick={() => refine('')}/>
                                   }}
                        />

                    </form>
                    {(this.state.showList && currentRefinement !== '') &&
                    <List dense className={`${classes.searchResults}`}>
                        {this.renderResultList()}
                    </List>
                    }
                </div>
            </ClickAwayListener>
        )
    };

    render() {
        return (
            <React.Fragment>
                {this.renderAutocompleteDesktop()}
                {/*Dialog not used yet*/}
                {/*Using the expanded option now (23 Oct)*/}
                {/*{this.renderAutocompleteDialog()}*/}
            </React.Fragment>
        )
    }
}

AlgoliaAutoComplete = withRouter(AlgoliaAutoComplete);
AlgoliaAutoComplete = connectAutoComplete(AlgoliaAutoComplete);

export default withStyles(customStyles)(AlgoliaAutoComplete);
